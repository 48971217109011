import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import ServicesGrid from '../components/ServicesGrid';
import ServicesSections from '../components/ServicesSections';
import LearnHero from '../components/LearnHero';
import ReviewBar from '../components/ReviewBar';
import TestimonialCarousel from '../components/TestimonialCarousel';

import jumbotron from '../img/home-jumbotron.png';
import SEO from '../components/SEO';
import CallToActionButton from '../components/CallToActionButton';

export const IndexPageTemplate = ({ mainpitch, services }) => (
  <div>
    <SEO title="Home" pathname="/" />
    <div className="container">
      <img
        style={{
          width: 'auto',
        }}
        src={jumbotron}
        alt="Organized Transitions"
      />
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="content markdown-content ">
          <div className="has-text-centered">
            <div>
              <h1 className="title">{mainpitch.title}</h1>
            </div>
            <div>
              <p>{mainpitch.description}</p>
            </div>
            <br />

            <CallToActionButton to="/moving-company" buttonText="Learn More" />
          </div>
        </div>
        <ServicesSections sectionItems={services.blurbs}></ServicesSections>
        {/* <ServicesGrid gridItems={services.blurbs} /> */}
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  mainpitch: PropTypes.object,
  services: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        mainpitch={frontmatter.mainpitch}
        services={frontmatter.services}
      />
      <LearnHero />
      <TestimonialCarousel />
      <ReviewBar />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        mainpitch {
          title
          description
        }
        services {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            linkTo
            heading
            textSections {
              isSubHeader
              text
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import CallToActionButton from './CallToActionButton';
import { Link } from 'gatsby';

const ServicesSections = ({ sectionItems }) => (
  <>
    {sectionItems.map((item, index) => (
      <section className="section" key={item.heading}>
        <div className="container content markdown-content service-section">
          <h1>
            <Link to={item.linkTo}>{item.heading}</Link>
          </h1>
          <Link to={item.linkTo}>
            <div className="img-right">
              {/* <div className={index % 2 === 0 ? 'img-right' : 'img-left'}> */}
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </Link>
          <div>
            {item.textSections.map((textSection) =>
              GetTextSection(textSection)
            )}
          </div>
        </div>
        <div className="column is-12 has-text-centered">
          <CallToActionButton to="/contact" buttonText="Contact Us" />
        </div>
      </section>
    ))}
  </>
);

const GetTextSection = (section) => {
  if (section.isSubHeader) return <h3>{section.text}</h3>;
  return <p>{section.text}</p>;
};

ServicesSections.propTypes = {
  sectionItems: PropTypes.array,
};

export default ServicesSections;
